import React from "react"
import { graphql } from "gatsby"

import LayoutHome from "../components/layout-home"
import SEO from "../components/seo"
import GatsbyImage from "gatsby-image"

const IndexPage = ({ data: { file } }) => (
  <LayoutHome>
    <SEO title="Home"/>
    <div>
      <div className="main-logo">
        <GatsbyImage fluid={file.childImageSharp.fluid} alt="Cyberdelphos mascot" />
      </div>
      <h2>Desarrollo front-end web y dispositivos móviles.</h2>
      <p>
        ¡Hola! Cyberdelphos es una idea 100% mexicana.
        Estoy trabajando en algunas apps que podrían ser de utilidad para tí.
        Próximamente encontrarás información al respecto.
        Mientras tanto, te invito a visitar mi blog de desarrollo.
      </p>
    </div>
  </LayoutHome>
)

export default IndexPage
export const query = graphql`
  {
    file(relativePath: {eq: "cyberdelphos.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
