import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const HeaderHome = ({ siteTitle }) => (
  <header className="home mb-auto">
    <div className="inner">
      <h3 style={{float: 'left'}}>Cyberdelphos</h3>
      <nav className="nav nav-masthead justify-content-end">
        <Link className="nav-link" to="/blog">Blog</Link>
      </nav>
    </div>
  </header>
)

HeaderHome.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderHome.defaultProps = {
  siteTitle: ``,
}

export default HeaderHome
