/**
 * LayoutHome component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import HeaderHome from "./header-home"

const LayoutHome = ({ children }) => {
  return (
    <div className="text-center">
      <div className="home-container d-flex w-100 p-3 mx-auto flex-column">
        <HeaderHome siteTitle="Cyberdelphos"/>
        <main role="main" className="inner cover">
          {children}
        </main>
        <footer className="mastfoot mt-auto">
          Cyberdelphos ©2014-{new Date().getFullYear()}
        </footer>
      </div>
    </div>
  )
}

LayoutHome.propTypes = {
  children: PropTypes.node.isRequired
}

export default LayoutHome
